import React, {useEffect, useState} from 'react';
import {TextField} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import './inputFields.css'





const theme = createTheme({
    palette: {
        primary: {
            main: '#27a17c',

            contrastText: '#47008F'
        },
        secondary: {
            main: '#E0C2FF',
            light: '#F5EBFF',

            contrastText: '#47008F',
        },
    },
});


const Form = () => {
    const [formValues, setFormValues] = useState({
        billingfirstname: '',
        billinglastname: '',
        billingpremise: '',
        currencyiso3a: 'GBP',
        mainamount: '',
        sitesecuritytimestamp: '',
    });

    const [amountValidationMessage, setAmountValidationMessage] = useState('');
    const [sitesecurity, setSitesecurity] = useState('');

    useEffect(() => {
        // Generate timestamp once when the component mounts
        const currentDate = new Date();

        const year = currentDate.getUTCFullYear();
        const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0'); // Add 1 to month since it's zero-based
        const day = String(currentDate.getUTCDate()).padStart(2, '0');
        const hours = String(currentDate.getUTCHours()).padStart(2, '0');
        const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0');

        const currentTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        setFormValues((prevValues) => ({ ...prevValues, sitesecuritytimestamp: currentTimestamp }));
    }, []);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });


        if (name === 'mainamount') {
            validateAmount(value);
        }
    };

    const validateAmount = (amount) => {
        const numericAmount = parseFloat(amount);

        if (isNaN(numericAmount) || numericAmount < 5000 || numericAmount > 200000) {
            setAmountValidationMessage('Minimum amount is 5000');
        } else {
            setAmountValidationMessage('');
        }
    };

    const isFormValid = Object.values(formValues).every(value => value.trim() !== '');

    useEffect(() => {
        // Log the updated sitesecurity state whenever it changes
       // console.log('Updated sitesecurity', sitesecurity );

        // If sitesecurity is not empty, submit the form
        if (sitesecurity !== '') {
            document.getElementById('paymentForm').submit();
        }
    }, [sitesecurity]); // Watch changes in sitesecurity state

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        console.log('Start of handleFormSubmit');
            // Generate timestamp once when the component mounts
        document.getElementById('submit-button').disabled = true;
        // Step 1: Send data to backend to generate hash
        try {
            const response = await fetch('/index.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sitereference: 'fisfly120289', // Replace with your dynamic value
                    stprofile: 'placanje', // Replace with your dynamic value
                    merchantemail: 'accounts@f-is-fly.com', // Replace with your dynamic value
                    sitesecuritytimestamp: formValues.sitesecuritytimestamp,
                    // Add other necessary fields for hash generation
                }),
            });



            if (!response.ok) {
              throw   new Error('Failed to generate hash');
            }

            const data = await response.json();
            console.log('Received data')

            // Set the generated hash to the state
            setSitesecurity(data.hash);




        } catch (error) {
            console.error('Error generating hash:', error.message);
            document.getElementById('submit-button').disabled = false;
        }

      // console.log('Form data before submission:', new FormData(document.getElementById('paymentForm')));
        // Step 2: Continue with the form submission

      // document.getElementById('paymentForm').submit();
    };




    return (
        <div className="flex items-center justify-center h-screen text-white px-5 md:px-0">
            <ThemeProvider theme={theme}>
                <form
                    id="paymentForm"
                    method="POST"
                    action="https://payments.securetrading.net/process/payments/details"
                    onSubmit={handleFormSubmit}
                    className="flex flex-col justify-center space-y-10 border-2 border-[#27a17c] bg-[#252525] rounded-lg px-10 py-12"
                >
                    <p className="font-bold text-2xl text-white">PCD Deposit</p>

                    <input type="hidden" name="version" value="2"/>
                    <input type="hidden" name="ruleidentifier" value="STR-6"/>
                    <input type="hidden" name="ruleidentifier" value="STR-4"/>


                    <TextField id="outlined-basic" label="First name"
                               variant="outlined" color="primary" type="text"  name="billingfirstname"
                               value={formValues.billingfirstname}
                               onChange={handleInputChange}
                               className="bg-[#151515] px-3 py-2 rounded-lg  custom-text-field  "
                               InputLabelProps={{
                                   style: {
                                       color: '#888',
                                   },
                               }}
                    />
                    <TextField id="outlined-basic" label="Last name"
                               variant="outlined" color="primary" type="text" name="billinglastname"
                               className="bg-[#151515] px-3 py-2 rounded-lg custom-text-field  "
                               value={formValues.billinglastname}
                               onChange={handleInputChange}
                               InputLabelProps={{
                                   style: {
                                       color: '#888',
                                   },
                               }}
                    />
                    <TextField id="outlined-basic" label="Unique reference number"
                               variant="outlined" type="text" name="billingpremise"
                               className="bg-[#151515] px-3 py-2 rounded-lg custom-text-field"
                               value={formValues.billingpremise}
                               onChange={handleInputChange}
                               InputLabelProps={{
                                   style: {
                                       color: '#888',
                                   },
                               }}
                    />
                    <div className="space-x-3 flex flex-row">
                        <select id="currency" name="currencyiso3a"
                                className="bg-[#151515] text-white font-bold   px-3 rounded-lg h-14"
                                value={formValues.currencyiso3a}
                                onChange={handleInputChange}
                        >
                            <option value="GBP">GBP</option>
                            <option value="EUR">EUR</option>
                            <option value="USD">USD</option>
                        </select>
                        <div className="flex flex-col">
                            <TextField id="outlined-basic" label="Amount"
                                       variant="outlined" type="text" name="mainamount"
                                       className="bg-[#151515] px-3 py-2 rounded-lg  custom-text-field"
                                       value={formValues.mainamount}
                                       onChange={handleInputChange}
                                       InputLabelProps={{
                                           style: {
                                               color: '#888',
                                           },
                                       }}
                            />
                            <div style={{ height: '16px' }}>
                                {amountValidationMessage && (
                                    <p className="text-red-500 text-xs mt-1">{amountValidationMessage}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <input type="hidden" name="sitereference" value="fisfly120289"/>
                    <input type="hidden" name="stprofile" value="placanje"/>
                    <input type="hidden" name="merchantemail" value="accounts@f-is-fly.com"/>
                    <input type="hidden" name="sitesecurity" value={sitesecurity}/>
                    <input type="hidden" name="sitesecuritytimestamp" value={formValues.sitesecuritytimestamp}/>
                    <button
                        type="submit"
                        id="submit-button"
                        className={`${
                            isFormValid && !amountValidationMessage ? 'bg-[#27a17c] hover:bg-[#18634d]' : 'bg-[#18634d] text-white opacity-30'
                        } text-white font-bold py-[18px] rounded-lg`}
                        disabled={!isFormValid || !!amountValidationMessage}
                    >
                        DEPOSIT
                    </button>
                </form>
            </ThemeProvider>
        </div>
    )

}

export default Form;