import './App.css';
import Form from "./components/Form";



function App() {
  return (
    <div className="App bg-teteri bg-cover md:bg-cover lg:bg-auto text-white">
        <Form/>
    </div>
  );
}

export default App;
